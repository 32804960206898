@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

iconify-icon {
  fill: #ffffff;
  color: #ffffff;
}

.icon-link iconify-icon:hover {
  fill: #0ca5e9;
  color: #0ca5e9;
}

.icon-link iconify-icon {
  margin: 10px 2.5px 0px 2.5px;
  fill: #ffffff;
  color: #ffffff;
}

.disabled.icon-link iconify-icon {
  pointer-events: none;
  fill: #323232;
  color: #323232;
}

.active .menu-link {
  @apply bg-black-russian-700;
  box-shadow: inset -2px 0 0 #0ca5e9;
}
.active .mobile-link {
  @apply bg-black-russian-700;
  box-shadow: inset -2px 0 0 #0ca5e9;
}
.bg-info {
  background-color: #0ca5e9;
}

.bg-primary {
  background-color: #0d6efd;
}
.bg-danger {
  background-color: #dc3545;
}
.bg-warning {
  background-color: #ffc107;
}
.bg-success {
  background-color: #198754;
}

html {
  @apply bg-black-russian-900;
}
